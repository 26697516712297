<template>
  <div class="m-our-services">
    <div class="container">
      <h3 class="subtitle">{{ $t("Serviciile Noastre") }}</h3>
      <h2 class="title">{{ $t("Descoperă cum putem să te ajutăm") }}</h2>
      <div class="services">
        <div
          v-for="(service, i) in services"
          :key="`service-${i}`"
          class="service"
          @click="setRoute(service.link)"
        >
          <div class="service__container">
            <div class="service__icon">
              <SfIcon :icon="service.icon" size="2.5rem" />
            </div>
            <span class="service__title">{{ $t(service.title) }}</span>
            <div class="service__image">
              <img :src="service.image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SfIcon } from "@storefront-ui/vue";
export default {
  components: {
    SfIcon,
  },
  data() {
    return {
      services: [
        {
          title: "Curățare și Spălare",
          icon: "washing_machine",
          image: require("@/assets/images/wash.jpg"),
          link: "Services",
        },
        {
          title: "Călcare",
          icon: "iron",
          image: require("@/assets/images/ironing.jpg"),
          link: "Services",
        },
        {
          title: "Îndepărtarea Petelor",
          icon: "shirt",
          image: require("@/assets/images/stain.jpg"),
          link: "Services",
        },
        {
          title: "Colectare și Livrare Comandă la Domiciliu",
          icon: "delivery_2",
          image: require("@/assets/images/delivery2.jpg"),
          link: "Services",
        },
      ],
    };
  },
  methods: {
    setRoute(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-our-services {
  margin: var(--spacer-3xl) 0;
  @include for-mobile {
    margin: 60rem 0 0;
  }
  .subtitle {
    text-transform: uppercase;
    color: var(--c-primary-darken);
    font-family: var(--font-family-primary);
    font-size: var(--font-base);
    font-weight: var(--font-bold);
    margin: 0;
    text-align: center;
  }
  .title {
    font-family: var(--font-family-secondary);
    font-size: 2.5rem;
    font-weight: var(--font-bold);
    margin: var(--spacer-sm) 0;
    text-align: center;
    @include for-mobile {
      font-size: 2rem;
      max-width: 90%;
      margin: 0 auto;
    }
  }
  .services {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .service {
    flex: 1 1 calc(25% - var(--spacer-sm) * 2);
    max-width: calc(25% - var(--spacer-sm) * 2);
    background: var(--c-white);
    border-radius: 10px;
    margin: var(--spacer-sm);
    height: 24rem;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.1));
    cursor: pointer;
    transition: 0.3s ease;
    @include for-mobile {
      flex: 1 1 calc(100% - var(--spacer-sm) * 2);
      max-width: calc(100% - var(--spacer-sm) * 2);
      height: 14rem;
    }
    &:hover {
      background-color: var(--c-primary-darken);

      .service {
        &__title {
          color: var(--c-white);
          &::before {
            background-color: var(--c-white);
          }
        }
        &__icon {
          --icon-color: var(--c-white);
        }
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      padding: var(--spacer-lg);
      @include for-mobile {
        padding: var(--spacer-sm) var(--spacer-lg);
      }
    }
    &__icon {
      --icon-color: var(--c-primary-darken);
      margin: var(--spacer-sm) 0 var(--spacer-xl);
      @include for-mobile {
        margin: var(--spacer-sm) 0;
      }
    }
    &__title {
      position: relative;
      padding-top: var(--spacer-sm);
      font-weight: var(--font-medium);
      font-size: var(--font-lg);
      height: 2.75rem;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 2px;
        width: 4rem;
        background-color: var(--c-primary-darken);
      }
    }
    &__image {
      position: absolute;
      bottom: 0;
      right: 0;
      opacity: 0.2;
      width: 100%;
      height: 100%;
      z-index: -1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow-clip-margin: unset;
      }
    }
  }
}
</style>
