<template>
  <div class="o-section-offset container">
    <div class="offset-container">
      <slot />
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-section-offset {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 28rem;

  .offset-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 4rem);
    max-width: 1250px;
    background-color: white;
    text-align: center;
    border-radius: 14px;
    padding: 3rem var(--spacer-lg);
    transform: translate(-50%, -27%);
    filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.1));

    @include for-mobile {
      transform: translate(-50%, -10%);
      padding: 3rem var(--spacer-sm);
    }
  }
}
</style>
