<template>
  <div class="home">
    <div class="section welcome">
      <oHeroStatic />
      <oSectionOffset>
        <mHowItWorks />
      </oSectionOffset>
      <mOurServices />
      <mReviews />
    </div>
  </div>
</template>

<script>
import oHeroStatic from "../components/organisms/o-hero-static.vue";
import oSectionOffset from "../components/organisms/o-section-offset.vue";
import mHowItWorks from "../components/molecules/m-how-it-works.vue";
import mOurServices from "../components/molecules/m-our-services.vue";
import mReviews from "../components/molecules/m-reviews.vue";

export default {
  name: "Home",
  metaInfo() {
    return {
      title: this.$t("Acasă"),
    };
  },
  components: {
    oHeroStatic,
    oSectionOffset,
    mHowItWorks,
    mOurServices,
    mReviews,
  },
  data() {
    return {};
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.home {
  // height: 140vh;
  display: block;
}
</style>
