<template>
  <div class="m-reviews">
    <div class="container">
      <h3 class="subtitle">{{ $t("Părerile Clienților") }}</h3>
      <h2 class="title">{{ $t("Află ce spun clienții noștri despre noi") }}</h2>

      <SfCarousel class="reviews" :settings="settings">
        <SfCarouselItem v-for="(review, i) in reviews" :key="`review-${i}`">
          <div class="review" @click="openExternal(review.link)">
            <SfIcon icon="quotes" class="review__quote" />
            <h4 class="review__title">
              {{ $t(review.title) }}
            </h4>
            <p class="review__description">
              {{ $t(review.description) }}
            </p>
            <div class="review__user">
              <div>
                <SfRating :score="review.rating" class="review__user--rating" />
                <span class="review__user--name">{{ review.user }}</span>
              </div>
              <img
                class="review__icon"
                src="@/assets/icons/social/google.png"
                alt=""
              />
            </div>
          </div>
        </SfCarouselItem>
      </SfCarousel>
    </div>
  </div>
</template>
<script>
import { SfIcon, SfRating, SfCarousel } from "@storefront-ui/vue";

export default {
  components: {
    SfIcon,
    SfRating,
    SfCarousel,
  },
  data() {
    return {
      settings: {
        animationDuration: 400,
        rewind: true,
        slidePerPage: false,
        perView: 3,
        peek: {
          before: 60,
          after: 60,
        },
        autoplay: 5000,
        breakpoints: {
          768: {
            perView: 1,
            peek: {
              before: 30,
              after: 30,
            },
          },
        },
      },
      reviews: [
        {
          title: "Am fost foarte mulțumită!",
          description:
            "Am apelat la serviciile lor pentru spălat 3 paturi mari. Am fost foarte mulțumită. O să revin și cu alte lucruri la curățat la ei. Au fost prompți și au respectat termenul stabilit la început. Mulțumesc!",
          rating: 5,
          user: "dalia stoeanovici",
          link: "https://maps.app.goo.gl/fqnAc3qcshmXS2tx8",
        },
        {
          title: "Comunicare excelentă!",
          description:
            "Salutare! Aveam o plapumă destul de groasă ce stătea prin dulap și acumula praf, astfel că avea nevoie de o curățare urgentă, iar neștiind unde să merg, am deschis maps-ul și bine am făcut. Am dat un search, i-am găsit pe cei de la Mr Jeff, am văzut că aveau review-uri bune și zis și făcut, în aceeași zi eram la ei. Am lăsat plapuma, iar peste alte două zile era gata, impecabil spălată, iar mirosul cum nu se poate mai fain. Comunicare excelentă, anunțat pe WhatsApp în momentul în care era gata, iar personalul este foarte friendly. See you next time, Mr Jeff!",
          rating: 5,
          user: "Marian Avram",
          link: "https://maps.app.goo.gl/X6NRJymKsvyJWs2q7",
        },
        {
          title: "Recomand cu încredere!",
          description:
            "Recomand cu încredere acestă curățătorie atât pentru personalul de la recepție (care îți explică de la început modul de curățare, riscurile aferente - în funcție de materialul articolului pe care intenționezi să îl cureți), dar și pentru serviciile oferite (profesionalism, atenție la petele existente pe material, rapiditate).",
          rating: 5,
          user: "Livia Beatrice Timofte",
          link: "https://maps.app.goo.gl/7r4F3SVbeASuDtrF9",
        },
        {
          title: "Foarte mulțumit! Recomand cu încredere!",
          description:
            "Hainele au fost curățate și călcate într-un timp foarte scurt și la un preț avantajos. Au și niște pachete de prețuri foarte bune pentru 6 și pentru 12 cămăși. Program de lucru foarte satisfăcător. Situat la 2 pași de Mall Coresi.",
          rating: 5,
          user: "Bogdan Nacu",
          link: "https://maps.app.goo.gl/cP9vWHk6WUyb4SRt7",
        },
        {
          title: "Recomand!",
          description:
            "Excelentă oferta, serviciile, dar mai ales interacțiunea cu staff-ul. Recomand, sunt bucuros că i-am descoperit!",
          rating: 5,
          user: "Dragos-Cristian FINARU",
          link: "https://maps.app.goo.gl/UD4gnAG7AqX5prfm9",
        },
        {
          title: "Sunt mulțumită de această firmă!",
          description:
            "Sunt mulțumită de această firmă. Personal foarte amabil și sunt serioși. Pantalonul și sacoul au ieșit super. Mulțumim mult!",
          rating: 5,
          user: "Simona Carla",
          link: "https://maps.app.goo.gl/JdoPNUA1cNtHhDt49",
        },
        {
          title: "Servicii de calitate!",
          description:
            "Întotdeauna am avut parte de servicii de calitate aici! Este o doamnă care mi-a oferit consultanță mereu, legată de posibilitatea sau imposibilitatea spălării/îndepărtării unei pete anume sau a unui material anume și am comunicat foarte bine!",
          rating: 5,
          user: "Andreea Brojbeanu",
          link: "https://maps.app.goo.gl/vYETjfcPKPi5aM7v5",
        },
        {
          title: "Foarte profesioniști!",
          description:
            "Foarte profesioniști, calitativi și la prețuri decente, recomand cu drag!",
          rating: 5,
          user: "Nicolae Grinciuc",
          link: "https://maps.app.goo.gl/xNufWpMAZTiEvjd29",
        },
        {
          title: "O să revin cu toată încrederea!",
          description:
            "Am dus o rochie de mireasă la Mr Jeff. Rochia arăta într-un mare fel, mai ales partea de jos. Dar am fost uimită când am luat-o arătând ca nouă! Doamna de acolo foarte ok. O să revin cu toată încrederea!",
          rating: 5,
          user: "Coltofean Anna",
          link: "https://maps.app.goo.gl/GSNpns6cBktbU3iW7",
        },
        {
          title: "Recomandăm cu foarte mare drag!",
          description:
            "Folosim mereu serviciile! Recomandăm cu foarte mare drag!",
          rating: 5,
          user: "Biserica Lutherană Confesională",
          link: "https://maps.app.goo.gl/Hx5wBQWZmBdBzjVS6",
        },
        {
          title: "Foarte mulțumit!",
          description:
            "Foarte mulțumit! Servicii profesioniste de curățare. Personal foarte amabil.",
          rating: 5,
          user: "marius ilinca",
          link: "https://maps.app.goo.gl/8QRYkhsomxmmzAf9A",
        },
        {
          title: "Recomand!",
          description: "Prețuri avantajoase și punctualitate! Recomand!",
          rating: 5,
          user: "Ocneanovici George",
          link: "https://maps.app.goo.gl/4gKcF31pKJSTuuJs9",
        },
        {
          title: "Servicii profesioniste!",
          description: "Servicii profesioniste! Calitate nota 10.",
          rating: 5,
          user: "Cristian Pirvu",
          link: "https://maps.app.goo.gl/NGhDBqW2v7c2YYno8",
        },
        {
          title: "Recomand cu drag!",
          description:
            "Recomand cu drag, am fost foarte mulțumită de fiecare dată!",
          rating: 5,
          user: "tatiana adam",
          link: "https://maps.app.goo.gl/1EWKhTR4wxijPuhG8",
        },
        {
          title: "O spălătorie minunată!",
          description:
            "O spălătorie minunată! Mereu foarte amabili și lucrurile bine curățate! Mulțumim!",
          rating: 5,
          user: "Ana Solomon",
          link: "https://maps.app.goo.gl/xSHRJmYPgbEzKC2D7",
        },
        {
          title: "Experiența a fost super!",
          description:
            "Experiența a fost super! Am avut nevoie de curățătorie chimică pentru un costum + cămașă și aveam un timp limitat la dispoziție. Totul a fost gata la timp și hainele arătau foarte bine, curate și călcate!",
          rating: 5,
          user: "Andreea Mihai",
          link: "https://maps.app.goo.gl/VyTX3mJyZQAv4QaS9",
        },
        {
          title: "Sunteți cei mai tari!",
          description:
            "Bună dimineața, vreau să îmi spun punctul de vedere pentru această echipă minunată. Sunt mai mult decât mulțumită de toate serviciile! Nu am cuvinte de promptitudine și responsabilitate! Sunteți minunați și nu schimb cu altă curățătorie nici dacă mi le face gratis!",
          rating: 5,
          user: "Maricica Liliana Dodan",
          link: "https://maps.app.goo.gl/1icvoQtYNcKPn7MK6",
        },
        {
          title: "Mulțumesc Mr Jeff!",
          description:
            "Mr Jeff este o spălătorie la care o să îmi curăț întotdeauna hainele, bineînțeles dacă își păstrează calitatea. Am rămas frumos surprinsă de atenția cu care mi-au curățat hainele dar și o pilotă, plus comunicarea excelentă cu personalul.",
          rating: 5,
          user: "Marcela Sistac",
          link: "https://maps.app.goo.gl/2fsGEU3s88eLn1kA8",
        },
        {
          title: "Recomand Mr Jeff",
          description:
            "Calitatea serviciului de curățare - impecabil, efectuat într-un termen mulțumitor de scurt. Termenul a fost respectat și mi s-a amintit prin mesaj că pot merge să ridic piesa lăsată la curățat. Prețul sub media pieței. Recomand Mr Jeff!",
          rating: 5,
          user: "Dan Zamfiroiu",
          link: "https://maps.app.goo.gl/P9oxoyGxAPbM2Rtr7",
        },
        {
          title: "Adevărați profesioniști!",
          description:
            "Adevărați profesioniști! Îți expun riscurile la predarea hainelor pentru curățat și se încadrează în termenul menționat pentru predarea hainelor curate. Servicii de curățare de calitate. Recomand!",
          rating: 5,
          user: "DimianMihaela Dimian",
          link: "https://maps.app.goo.gl/2KFfNcKf55jcUdXJA",
        },
      ],
    };
  },
  methods: {
    openExternal(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-reviews {
  margin: var(--spacer-2xl) 0 0;
  padding: var(--spacer-2xl) 0;
  background-color: var(--c-white);
  .subtitle {
    text-transform: uppercase;
    color: var(--c-primary-darken);
    font-family: var(--font-family-primary);
    font-size: var(--font-base);
    font-weight: var(--font-bold);
    margin: 0;
    text-align: center;
  }
  .title {
    font-family: var(--font-family-secondary);
    font-size: 2.5rem;
    font-weight: var(--font-bold);
    margin: var(--spacer-sm) 0;
    text-align: center;
    @include for-mobile {
      font-size: 2rem;
      max-width: 85%;
      margin: 0 auto;
    }
  }

  .reviews {
    margin: var(--spacer-xl) 0;
  }
  .review {
    // width: 18rem;
    background-color: var(--c-white);
    border-radius: 16px;
    padding: var(--spacer-base);
    margin: var(--spacer-sm);
    filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.1));
    cursor: pointer;
    &__quote {
      --icon-color: var(--c-primary-darken);
      --icon-size: 3.25rem;
      margin: var(--spacer-sm) 0 0;
    }
    &__title {
      font-family: var(--font-family-primary);
      font-size: var(--font-xl);
      line-height: 1.4;
      height: 4.25rem;
      overflow: hidden;
    }
    &__description {
      margin: var(--spacer-lg) 0;
      line-height: 1.6;
      color: var(--c-dark);
      font-size: var(--font-base);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
      height: 8rem;
    }
    &__user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 2px solid var(--c-light);
      padding: var(--spacer-base) 0 0;
      &--rating {
        &::v-deep .sf-icon {
          --icon-size: 1.125rem !important;
        }
      }
      &--name {
        display: block;
        margin-top: var(--spacer-2xs);
        font-size: var(--font-sm);
        font-weight: var(--font-normal);
        font-family: var(--font-family-primary);
      }
    }
    &__icon {
      display: flex;
      width: 1.5rem;
      aspect-ratio: 1;
    }
  }
}
</style>
