<template>
  <div class="o-hero-static">
    <div class="background-hero">
      <img src="@/assets/images/webp/homepage.webp" alt="homepage" />
    </div>
    <div class="container content__wrapper">
      <div class="content">
        <!-- <h4 class="content__subtitle">Get 40% off on 1st Month subscription</h4> -->
        <h1 class="content__title">
          {{ $t("Servicii de Curățătorie cu Ridicare și Livrare") }}
        </h1>
        <p class="content__description">
          {{
            $t(
              "Preluăm, spălăm, igienizăm și călcăm toate rufele tale, pentru ca tu să ai mai mult timp liber pentru activitățile tale preferate"
            )
          }}
        </p>
        <button
          class="content__button"
          @click="$router.push({ name: 'Contact' })"
        >
          {{ $t("Contactează-ne") }}
        </button>
        <!-- <div class="content__features">
          <div class="content__feature">
            <div class="content__feature--title">18M+</div>
            <div class="content__feature--description">Happy Customers</div>
          </div>
          <div class="content__feature">
            <div class="content__feature--title">10+</div>
            <div class="content__feature--description">Years of Experience</div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  methods: {
    goTo(link) {
      const toRoute = `/${this.$i18n.locale}${link}`;
      this.$router.push(toRoute);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-hero-static {
  position: relative;
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  .background-hero {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // background-color: var(--c-primary);
      background: linear-gradient(
        180deg,
        rgba(65, 182, 230, 0.55) 25.29%,
        rgba(65, 182, 230, 0.448913) 51.2%,
        var(--c-dark-lighten) 100%
      );
      opacity: 0.55;
      content: "";
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      transform: translateY(-50%);
      object-fit: cover;
      z-index: -1;
      overflow-clip-margin: unset;
    }
  }
  .content {
    max-width: 40rem;
    padding: 0 8rem;
    @include for-mobile {
      padding: 0 1rem;
    }
    &::before {
      content: "";
      position: absolute;
      width: 24rem;
      height: 24rem;
      top: -8rem;
      left: 0;
      border-radius: 50%;
      background-color: var(--c-dark);
      opacity: 0.65;
      z-index: -1;
      @include for-mobile {
        width: 14rem;
        height: 14rem;
        top: -4rem;
        left: -2rem;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 24rem;
      height: 24rem;
      top: 4rem;
      left: 14rem;
      border-radius: 50%;
      background-color: var(--c-dark);
      opacity: 0.65;
      z-index: -1;
      @include for-mobile {
        width: 14rem;
        height: 14rem;
        top: 2rem;
        left: 6rem;
      }
    }
    &__subtitle {
      background-color: var(--c-primary);
      font-weight: var(--font-medium);
      font-size: var(--font-sm);
      color: var(--c-white);
      border-radius: 200px;
      padding: var(--spacer-xs) var(--spacer-lg);
      width: fit-content;
      margin: 0 0 var(--spacer-xl);
    }
    &__title {
      font-size: 3rem;
      font-weight: var(--font-black);
      font-family: var(--font-family-secondary);
      color: var(--c-white);
      margin: 0 0 var(--spacer-base);
      @include for-mobile {
        font-size: 2rem;
      }
    }
    &__description {
      font-size: var(--font-base);
      font-family: var(--font-family-secondary);
      font-weight: var(--font-normal);
      letter-spacing: 0.5px;
      line-height: 1.8;
      color: var(--c-white);
      margin: 0;
      @include for-mobile {
        line-height: 1.4;
      }
    }
    &__button {
      color: var(--c-white);
      background-color: var(--c-primary-darken);
      padding: 0.75rem var(--spacer-xl);
      font-size: var(--font-lg);
      font-family: var(--font-family-secondary);
      border-radius: 200px;
      outline: none;
      border: none;
      width: 50%;
      margin-top: var(--spacer-2xl);
      filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.2));
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        background-color: #1e9acd;
      }
      @include for-mobile {
        width: 100%;
      }
    }
    &__features {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--spacer-sm);
      width: fit-content;
      margin: var(--spacer-xl) 0 0;
    }
    &__feature {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;

      &--title {
        font-family: var(--font-family-primary);
        font-size: 2.25rem;
        font-weight: var(--font-bold);
        color: var(--c-white);
        line-height: 1.6;
      }
      &--description {
        font-size: var(--font-xs);
        font-weight: var(--font-medium);
        color: var(--c-white);
        max-width: 75px;
        line-height: 1.2;
      }
    }
  }
}
</style>
