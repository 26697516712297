<template>
  <div class="m-how-it-works how-it-works">
    <h3 class="how-it-works__subtitle">
      {{ $t("Cum funcționează") }}
    </h3>
    <h2 class="how-it-works__title">
      {{ $t("Rezolvă în 3 pași simpli") }}
    </h2>
    <div class="how-it-works__steps">
      <div class="how-it-works__step">
        <h5 class="how-it-works__step--subtitle">
          {{ $t("Pas 1") }}
        </h5>
        <h4 class="how-it-works__step--title">
          {{ $t("Pregăteşte articolele") }}
        </h4>
        <p class="how-it-works__step--description">
          {{
            $t(
              "Selectează articolele care trebuie spălate, călcate sau curăţate uscat. Poți opta pentru unul din abonamentele sau pachetele noastre pentru un preț mai mult decât accesibil."
            )
          }}
        </p>
        <div class="how-it-works__step--background">
          <img src="@/assets/svgs/collect.svg" alt="collect" />
        </div>
      </div>
      <div class="how-it-works__step">
        <h5 class="how-it-works__step--subtitle">
          {{ $t("Pas 2") }}
        </h5>
        <h4 class="how-it-works__step--title">
          {{ $t("Contactează-ne pentru colectare") }}
        </h4>
        <p class="how-it-works__step--description">
          {{
            $t(
              "Ia legătura telefonic sau pe WhatsApp cu unul din operatorii noștri pentru a stabili detaliile despre comanda ta."
            )
          }}
        </p>
        <div class="how-it-works__step--background">
          <img src="@/assets/svgs/pick-date.svg" alt="pick-date" />
        </div>
      </div>
      <div class="how-it-works__step">
        <h5 class="how-it-works__step--subtitle">
          {{ $t("Pas 3") }}
        </h5>
        <h4 class="how-it-works__step--title">
          {{ $t("Bucură-te de rufele proaspăt spălate") }}
        </h4>
        <p class="how-it-works__step--description">
          {{
            $t(
              "Preluăm, spălăm, igienizăm și călcăm toate rufele tale, pentru ca tu să ai mai mult timp liber pentru activitățile tale preferate."
            )
          }}
        </p>
        <div class="how-it-works__step--background">
          <img src="@/assets/svgs/done.svg" alt="done" />
        </div>
      </div>
    </div>
    <button
      class="how-it-works__button"
      @click="setRoute('Services', '#preturi')"
    >
      {{ $t("Vezi prețurile noastre") }}
    </button>
  </div>
</template>
<script>
export default {
  methods: {
    setRoute(routeName, hash) {
      if (!hash) {
        this.$router.push({ name: routeName });
      } else {
        this.$router.push({ name: routeName, hash: hash });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-how-it-works {
  .how-it-works {
    &__button {
      color: var(--c-white);
      background-color: var(--c-primary-darken);
      padding: 0.75rem var(--spacer-2xl);
      font-size: var(--font-base);
      font-family: var(--font-family-secondary);
      border-radius: 200px;
      outline: none;
      border: none;
      margin-top: var(--spacer-sm);
      filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.2));
      cursor: pointer;
      transition: 0.3s ease;
    }
    &__subtitle {
      text-transform: uppercase;
      color: var(--c-primary-darken);
      font-family: var(--font-family-primary);
      font-size: var(--font-base);
      font-weight: var(--font-bold);
      margin: 0;
    }
    &__title {
      font-family: var(--font-family-secondary);
      font-size: 2.5rem;
      font-weight: var(--font-bold);
      margin: var(--spacer-sm) 0;
      @include for-mobile {
        font-size: 2rem;
      }
    }
    &__steps {
      display: flex;
      align-items: stretch;
      justify-content: center;
      padding: var(--spacer-base);
      flex-wrap: wrap;

      @include for-mobile {
        padding: 0;
      }
    }
    &__step {
      position: relative;
      flex: 1 1;
      padding: 0 4rem;
      min-height: 20rem;
      &:not(:last-child) {
        @include for-desktop {
          border-right: 2px solid var(--c-light);
        }
      }
      @include for-mobile {
        padding: var(--spacer-lg) var(--spacer-sm);
        flex: 1 1 100%;
      }

      &--subtitle {
        text-transform: uppercase;
        color: var(--c-primary-darken);
        font-family: var(--font-family-primary);
        font-size: var(--font-base);
        font-weight: var(--font-semibold);
        margin: 0;
      }
      &--title {
        font-family: var(--font-family-secondary);
        font-size: 1.35rem;
        font-weight: var(--font-bold);
        margin: var(--spacer-xs) 0;
        height: 3.5rem;
        position: relative;
        z-index: 1;
      }
      &--description {
        font-family: var(--font-family-primary);
        font-size: var(--font-sm);
        font-weight: var(--font-normal);
        line-height: 1.8;
        position: relative;
        z-index: 1;
      }
      &--background {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 85%;
        opacity: 0.45;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow-clip-margin: unset;
        }
        @include for-mobile {
          height: 75%;
        }
      }
    }
  }
}
</style>
